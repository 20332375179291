import { HTTP } from '../../utilities/http-common'
import { ServiceUrls } from '../../utilities/serviceUrls'
import { InteractionRequiredAuthError } from '@azure/msal-browser'
import { msalConfig, loginRequest, tokenRequest, msalInstance } from '../../msal/authConfig'
import { b2cPolicies } from '../../msal/policies'

let acquireTokenRedirectPromise = 'NONE'

const getDefaultState = () => {
  return {
    userRole: null,
    customToastData: null,
    availableTenantsList: [],
    selectedTenant: [],
    selectedTenantIdByRole: null,
    userProfile: null,
    accountId: null,
    idToken: null
  }
}
// initial state
const state = getDefaultState()

// getters
const getters = {

}
// actions
const actions = {
  getAdminTenants ({ commit, state }) {
    return HTTP.get(ServiceUrls.getAdminTenants)
      .then((res) => {
        let selectedTenant = []
        if (state.userRole === 'SysAdmin') {
          const tenantIds = sessionStorage.getItem('tenantIds')
          if (tenantIds) {
            const convertedTenantIds = JSON.parse(tenantIds).map(Number)
            selectedTenant = res.data.filter(tenant => convertedTenantIds.includes(Number(tenant.tenantID)))
          }
          if (!tenantIds || !selectedTenant) {
            selectedTenant = res.data.filter(tenant => Number(tenant.tenantID) === 0)
            sessionStorage.setItem('tenantIds', JSON.stringify([0]))
          }
        } else {
          selectedTenant = res.data
        }
        commit('setSelectedTenant', selectedTenant)
        commit('setAvailableTenantsList', res.data)
        return res
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  },
  updateAdminTenants ({ commit }, selectedTenant) {
    const stringOfTenants = selectedTenant.map(tenant => tenant.tenantID)
    sessionStorage.setItem('tenantIds', JSON.stringify(stringOfTenants))
    commit('setSelectedTenant', selectedTenant)
  },
  clearState ({ commit }) {
    return new Promise((resolve, reject) => {
      commit('resetState')
      commit('cities/resetState', '', { root: true })
      commit('settings/resetState', '', { root: true })
      commit('widgets/resetState', '', { root: true })
      commit('users/resetState', '', { root: true })
      resolve()
    })
  },
  signOut ({ dispatch, state }) {
    /**
     * You can pass a custom request object below. This will override the initial configuration. For more information, visit:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#request
     */
    const currentAccount = msalInstance.getAccountByHomeId(state.accountId)
    const logoutRequest = {
      account: currentAccount,
      postLogoutRedirectUri: msalConfig.auth.postLogoutRedirectUri
    }
    if (acquireTokenRedirectPromise !== 'IN_PROGRESS') {
      acquireTokenRedirectPromise = 'IN_PROGRESS'
      sessionStorage.removeItem('tenantIds')
      return msalInstance.logoutRedirect(logoutRequest)
    }
  },
  async setAccount ({ commit, dispatch }, account) {
    msalInstance.setActiveAccount(account)
    commit('setUserProfile', account.idTokenClaims)
    const role = account.idTokenClaims.govStackTenantId
    commit('setUserRole', role === '' ? 'Unauthorized' : role === '0' ? 'SysAdmin' : 'Admin')
    commit('setAccountId', account.homeAccountId)
    await dispatch('getToken')
  },
  async selectAccount ({ dispatch }) {
    await msalInstance.handleRedirectPromise().then(async (resp) => {
      acquireTokenRedirectPromise = 'NONE'
      if (resp !== null) {
        await dispatch('setAccount', resp.account)
      } else {
        // need to call getAccount here?
        const currentAccounts = msalInstance.getAllAccounts()
        if (currentAccounts.length < 1) {
          await msalInstance.loginRedirect(loginRequest)
        } else if (currentAccounts.length > 1) {
          /**
                         * Due to the way MSAL caches account objects, the auth response from initiating a user-flow
                         * is cached as a new account, which results in more than one account in the cache. Here we make
                         * sure we are selecting the account with homeAccountId that contains the sign-up/sign-in user-flow,
                         * as this is the default flow the user initially signed-in with.
                         */
          const accounts = currentAccounts.filter(account =>
            account.homeAccountId.toUpperCase().includes(b2cPolicies.names.signUpSignIn.toUpperCase()) &&
                            account.idTokenClaims.iss.toUpperCase().includes(b2cPolicies.authorityDomain.toUpperCase()) &&
                            account.idTokenClaims.aud === msalConfig.auth.clientId
          )

          if (accounts.length > 1) {
            // localAccountId identifies the entity for which the token asserts information.
            if (accounts.every(account => account.localAccountId === accounts[0].localAccountId)) {
              // All accounts belong to the same user
              await dispatch('setAccount', accounts[0])
            } else {
              // Multiple users detected. Logout all to be safe.
              await dispatch('signOut')
            };
          } else if (accounts.length === 1) {
            await dispatch('setAccount', accounts[0])
          }
        } else if (currentAccounts.length === 1) {
          await dispatch('setAccount', currentAccounts[0])
        }
      }
    }).catch(async () => {
      await msalInstance.loginRedirect(loginRequest)
    })
  },
  async acquireTokenRedirect () {
    // fallback to interaction when silent call fails
    acquireTokenRedirectPromise = 'IN_PROGRESS'
    return await msalInstance.acquireTokenRedirect(tokenRequest)
  },
  acquireTokenSilent ({ dispatch, state }) {
    /**
    * See here for more information on account retrieval:
    * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
    */
    /**
         *
         */
    return msalInstance.acquireTokenSilent(tokenRequest)
      .then((response) => {
        // In case the response from B2C server has an empty accessToken field
        // throw an error to initiate token acquisition
        if (!response.idToken || response.idToken === '') {
          throw new InteractionRequiredAuthError()
        }
        return response
      })
      .catch(async (error) => {
        if (error instanceof InteractionRequiredAuthError) {
          // fallback to interaction when silent call fails
          if (acquireTokenRedirectPromise === 'IN_PROGRESS') {
            return await dispatch('acquireTokenSilent')
          } else {
            return await dispatch('acquireTokenRedirect')
          }
        } else {
          return Promise.reject(error)
        }
      })
  },
  getToken ({ commit, dispatch }) {
    return dispatch('acquireTokenSilent')
      .then(response => {
        if (response) {
          try {
            commit('setIdToken', response.idToken)
            return response
          } catch (error) {
            commit('setIdToken', null)
            return Promise.reject(error)
          }
        }
      }).catch((error) => {
        commit('setIdToken', null)
        return Promise.reject(error)
      })
  }
}
// mutations
const mutations = {
  resetState (state) {
    Object.assign(state, getDefaultState())
  },
  setAccountId (state, payload) {
    state.accountId = payload
  },
  setIdToken (state, payload) {
    state.idToken = payload
  },
  setUserRole (state, payload) {
    state.userRole = payload
  },
  setCustomToastData (state, payload) {
    state.customToastData = payload
  },
  setSelectedTenant (state, payload) {
    state.selectedTenant = payload
    state.selectedTenantIdByRole = state.userRole === 'SysAdmin' ? payload.length ? payload[0].tenantID : null : null
  },
  setAvailableTenantsList (state, payload) {
    state.availableTenantsList = payload
  },
  setUserProfile (state, payload) {
    state.userProfile = payload
  }
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
