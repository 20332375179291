<script>
import { mapState } from 'vuex'
import { DISPLAY_MESSAGES } from '../../utilities/constants'
import {
  BIconX,
  BIconCheck2Circle,
  BIconXCircle,
  BIconInfoCircle,
  BIconExclamationTriangle
} from 'bootstrap-vue'

export default {
  name: 'CustomToaster',
  data () {
    return {
      count: 0
    }
  },
  components: {
    BIconX,
    BIconCheck2Circle,
    BIconInfoCircle,
    BIconXCircle,
    BIconExclamationTriangle
  },
  computed: {
    ...mapState({
      customToastData: (state) => state.common.customToastData
    })
  },
  methods: {
    showToast (data) {
      const message = data.key ? DISPLAY_MESSAGES[data.key] : data.message
      let iconTag = ''
      let title = ''
      if (data.type === 'success') {
        iconTag = 'b-icon-check-2-circle'
        title = 'Success'
      } else if (data.type === 'info') {
        iconTag = 'b-icon-info-circle'
        title = 'Info'
      } else if (data.type === 'warning') {
        iconTag = 'b-icon-exclamation-triangle'
        title = 'Warning'
      } else if (data.type === 'danger') {
        iconTag = 'b-icon-x-circle'
        title = 'Error'
      } else {
        iconTag = 'i'
      }
      const h = this.$createElement
      const id = `custom-toast-${this.count++}`
      const vNodeIcon = h('div', { class: ['icon-area'] }, [
        h(iconTag, { class: ['icon'] })
      ])
      const vNodeMsg = h('div', { class: ['message-area'] }, [
        h('div', { class: ['title'] }, `${title}`),
        h('div', {
          domProps: {
            innerHTML: `${message}`
          }
        })
      ])
      const vNodeClose = h('div', { class: ['close-area'] }, [
        h('b-icon-x', {
          class: ['close-icon'],
          on: { click: () => this.$bvToast.hide(id) }
        })
      ])
      this.$bvToast.toast([vNodeIcon, vNodeMsg, vNodeClose], {
        id: id,
        solid: false,
        toastClass: 'custom-toast',
        variant: data.type,
        noCloseButton: true,
        noAutoHide: false
      })
    }
  },
  watch: {
    customToastData (newValue, oldValue) {
      if (newValue) {
        if (newValue.message !== '401-Unauthorized') {
          this.showToast(newValue)
        }
      }
    }
  },
  render () {
    return null
  }
}
</script>
<style lang="scss" scoped>
</style>
