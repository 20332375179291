export const TinyEditorOptions = {
  apiKey: 'tmkr90atqt0tzb1w14w5iq5t19u2smyqzcu4ye43c8ghs4jq',
  initConfig: {
    statusbar: false,
    plugins:
      'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link  template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
    imagetools_cors_hosts: ['picsum.photos'],
    menubar: 'file edit view insert format tools table help',
    toolbar:
      'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image template link anchor codesample | ltr rtl',
    toolbar_sticky: false,
    autosave_ask_before_unload: true,
    autosave_interval: '30s',
    autosave_prefix: '{path}{query}-{id}-',
    autosave_restore_when_empty: false,
    autosave_retention: '2m',
    image_advtab: true,
    link_list: [
      { title: 'My page 1', value: 'http://www.tinymce.com' },
      { title: 'My page 2', value: 'http://www.moxiecode.com' }
    ],
    image_list: [
      { title: 'My page 1', value: 'http://www.tinymce.com' },
      { title: 'My page 2', value: 'http://www.moxiecode.com' }
    ],
    image_class_list: [
      { title: 'None', value: '' },
      { title: 'Some class', value: 'class-name' }
    ],
    importcss_append: true,
    file_picker_callback: function (callback, value, meta) {
      /* Provide file and text for the link dialog */
      if (meta.filetype === 'file') {
        callback('https://www.google.com/logos/google.jpg', {
          text: 'My text'
        })
      }

      /* Provide image and alt text for the image dialog */
      if (meta.filetype === 'image') {
        callback('https://www.google.com/logos/google.jpg', {
          alt: 'My alt text'
        })
      }
    },
    templates: [
      {
        title: 'New Table',
        description: 'creates a new table',
        content:
          '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td> </td><td> </td></tr></table></div>'
      },
      {
        title: 'Starting my story',
        description: 'A cure for writers block',
        content: 'Once upon a time...'
      },
      {
        title: 'New list with dates',
        description: 'New List with dates',
        content:
          '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>'
      }
    ],
    template_cdate_format: '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]',
    template_mdate_format: '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]',
    height: 290,
    image_caption: true,
    quickbars_selection_toolbar:
      'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
    noneditable_noneditable_class: 'mceNonEditable',
    toolbar_mode: 'sliding',
    contextmenu: 'link image imagetools table',
    content_style:
      'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
  }
}

export const UserGroupActionsOptions = [
  { text: 'Deactivate', value: 0 },
  { text: 'Activate', value: 1 },
  { text: 'Reset password', value: 2 },
  { text: 'View details', value: 3 }
]

export const LinkAccountFieldTypeOptions = [
  'Text', 'Password', 'Date', 'Amount'
]

export const WidgetDashboardCountList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

export const SupportedImagesTypes = ['jpeg', 'jpg', 'png', 'webp']
export const SupportedImageFileSizeLimit = 2 * 1024 * 1024 // 2 -> in MB

export const WidgetLogoFileSizeLimit = 5 * 1024 // 5 -> in KB

export const ColorRegEx = /^#[a-f0-9]{6}$/i
export const WidgetTypesSettings = [
  { widgetCategoryID: 1, name: 'contentWidgets', component: null, tabs: ['Configuration', 'Content', 'Notifications'] },
  { widgetCategoryID: 2, name: 'calendarWidgetSettings', component: 'CalendarWidget', tabs: ['Configuration', 'Content', 'Notifications', 'Settings'] },
  { widgetCategoryID: 3, name: 'adminNewsWidgetSettings', component: 'NewsWidget', tabs: ['Configuration', 'Content', 'Notifications'] },
  { widgetCategoryID: 4, name: 'locationWidgetSettings', component: 'LocationWidget', tabs: ['Configuration', 'Content', 'Notifications'] },
  { widgetCategoryID: 5, name: 'integrationWidgetSettings', component: 'IntegrationWidget', tabs: ['Configuration', 'Content', 'LinkAccounts', 'Notifications'] },
  { widgetCategoryID: 6, name: 'formBuilderWidgetSettings', component: 'FormBuilderWidget', tabs: ['Configuration', 'Content', 'Forms', 'Notifications'] },
  { widgetCategoryID: 7, name: 'engagementWidgetSettings', component: null, tabs: ['Configuration', 'Content', 'Notifications'] },
  { widgetCategoryID: 8, name: 'myWasteWidgetSettings', component: null, tabs: ['Configuration', 'Content', 'Notifications'] },
  { widgetCategoryID: 9, name: 'recreationWidgetSettings', component: 'RecreationWidget', tabs: ['Configuration', 'Content'] }
]

export const WidgetDescriptions = [
  {
    widgetCategoryName: 'Content',
    color: '#47B0C9',
    description: 'The content widget allows you to create a blank widget, where you can add in text and links to provide actionable items to citizens. The possibilities are endless with the content widget!'
  },
  {
    widgetCategoryName: 'News',
    color: '#F0483E',
    description: 'Integrated with i:Create News V3, this widget will pull news articles from your website. With a News widget, you can easily display any news articles in one central location in Citizen Portal.'
  },
  {
    widgetCategoryName: 'Calendar',
    color: '#E5CF2B',
    description: "Integrated with the Calendar module, pull events from your website's calendars. With a Calendar widget, you can easily display any meeting or event in one central location in Citizen Portal."
  },
  {
    widgetCategoryName: 'Location',
    color: '#AB53BF',
    description: 'Create your own location based widgets. Location based widgets can be used to display information to a logged in Citizen User based on their profile address.'
  },
  {
    widgetCategoryName: 'Integration',
    color: '#838383',
    description: 'Integration widgets can be used to create widgets that can integrate seamlessly with your systems to provide important information to your citizens through a secure data connection.'
  },
  {
    widgetCategoryName: 'Form builder',
    color: '#21B352',
    description: 'These widgets are integrated with form builder and can be used to create widgets that give the Citizens the option to find and submit forms from Citizen Portal.'
  },
  {
    widgetCategoryName: 'Engagement',
    color: '#4774C9',
    description: 'This out-of-the-box widget integrates Citizen Portal with your third-party engagement platform. This will allow citizens to follow specific projects all in one place.'
  },
  {
    widgetCategoryName: 'MyWaste',
    color: '#559A1B',
    description: 'With this out-of-the-box widget, you can make sure that your citizens not only know exactly when garbage or recycling collection days are for their home address but also receive reminders about their next pickup date.'
  },
  {
    widgetCategoryName: 'Recreation',
    color: '#F57F40',
    description: 'This out-of-the-box widget integrates Citizen Portal with your third-party recreation platform giving you the opportunity to increase citizen participation by giving your citizens a convenient way to find various city run programs.'
  }
]

export const IDLETIMEOUT = 30 * 60

export const FOOTER_HELP_URLS_LIST = [
  { name: 'Privacy', url: 'https://ghddigitalpss.com/privacy-policy/' },
  { name: 'Support', url: 'https://ghddigitalpss.com/support/ ' },
  { name: 'Terms of use', url: 'https://ghddigitalpss.com/terms-of-use/' }
]

export const HELP_INFO_LIST = [
  { name: "What's New", helpText: 'Get the details of each release of Citizen Portal, organized by release date.', url: 'https://resourcecentre.esolutionsgroup.ca/Modules/News/en?CategoryNames=Citizen+Portal+Release+Notes&TagWhiteList=' },
  { name: 'How to..', helpText: 'Get help with using a feature through our User guide with step-by-step instructions.', url: 'https://resourcecentre.esolutionsgroup.ca/en/citizen-portal/citizen-portal.aspx' },
  { name: 'API Docs', helpText: 'Access all the API documentations.', url: 'https://resourcecentre.esolutionsgroup.ca/en/citizen-portal/citizen-portal-api-documentation.aspx' },
  { name: 'Request Information', helpText: 'Request information, or assistance with using the product or a feature, questions about new or existing services and features, password reset etc.', url: 'https://esolutionsgroup.atlassian.net/servicedesk/customer/portal/5/group/20/create/105' },
  { name: 'Report a Problem', helpText: 'Report a problem, error message or other issues affecting normal operation of the product.', url: 'https://esolutionsgroup.atlassian.net/servicedesk/customer/portal/5/group/20/create/106' },
  { name: 'Support', helpText: 'Find information on how to email or call us for support.', url: 'https://ghddigitalpss.com/support/' }
]

export const SampleResponseRegEx = /[\\,()[\]]/g
export const EscapeChar = '#'

export const CONFIRM_DIALOG_MESSAGES = {
  CONFIRM: 'Please confirm',
  DEACTIVATE_USER: 'Once a user is deactivated, they will be unable to login to the portal unless activate again. Are you sure you want to deactivate this user?',
  ACTIVATE_USER: 'Once a user is activated, they will be able to login to the portal. Are you sure you want to activate the user?',
  RESET_PASSWORD_USER: 'A reset password link would be sent to the user\'s registered email address. Are you sure you want to send a reset password link to this user?',
  DEACTIVATE_USERS: 'Once the users are deactivated, they will be unable to login to the portal unless activate again. Are you sure you want to deactivate the selected users?',
  ACTIVATE_USERS: 'Once the users are activated, they will be able to login to the portal. Are you sure you want to activate the selected users?',
  RESET_PASSWORD_USERS: 'A reset password link would be sent to the user\'s registered email address. Are you sure you want to send a reset password link to the selected users?',
  DELETE_LINK_ACCOUNT: 'Link account would be deleted permanently. Are you sure you want to permanently delete this link account?',
  LINK_ACCOUNT_FIELD_REMOVE: 'Field will be deleted permanently. Are you sure you want to permanently delete this field?',
  DELETE_WIDGET: 'Widget will be deleted permanently. Are you sure you want to permanently delete this widget?',
  ACTIVATE_WIDGET: 'Widget will be activated and would be displayed on the citizen\'s dashboard. Are you sure you want to activate this widget?',
  DEACTIVATE_WIDGET: 'Widget will be deactivated and would not be displayed on the citizen\'s dashboard. You can activate it again later. Are you sure you want to deactivate this widget?',
  FORM_BUILDER_WIDGET_CATEGORY_REMOVE: 'Do you want to delete all the forms associated with this category? Select ‘No’ to remove the category only.',
  FORM_BUILDER_WIDGET_FORM_REMOVE: 'Form will be deleted permanently. Are you sure you want to permanently delete this form?',
  FORM_BUILDER_WIDGET_STATUS_REMOVE: 'Status will be deleted permanently. Are you sure you want to permanently delete this status?',
  CHART_REMOVE: 'Chart will be deleted permanently. Are you sure you want to permanently delete this chart?',
  TABLE_REMOVE: 'Table will be deleted permanently. Are you sure you want to permanently delete this table?'
}

export const DISPLAY_MESSAGES = {
  SOCIAL_MEDIA_USER_COUNT_ERROR: 'citizen(s) in your city are using this option to access their account on the portal. If you un-check this option, they will not be able to access their account. Are you sure you want to remove this option?',
  MULTIPLE_CITIES_ADD_WIDGET_ERROR: 'Please select one city only to add/update widget.',
  MULTIPLE_CITIES_GENERAL_SETTINGS_ERROR: 'Please select one city only to update general settings.',
  MULTIPLE_CITIES_EMAIL_SETTINGS_ERROR: 'Please select one city only to configure email settings.',
  MULTIPLE_CITIES_ADD_LINK_ACCOUNT_ERROR: 'Please select one city only to add link account.',
  MULTIPLE_CITIES_LINK_ACCOUNT_ERROR: 'Please select one city only to view link accounts.',
  MULTIPLE_CITIES_USER_DETAILS_ERROR: 'Please select one city only to view user details.',
  FAILED: 'Something went wrong, please try again.',
  ADD_WIDGET: 'Widget added successfully.',
  UPDATE_WIDGET: 'Widget updated successfully.',
  ADD_LINK_ACCOUNT: 'Link account added successfully.',
  UPDATE_LINK_ACCOUNT: 'Link account updated successfully.',
  ADD_EXTERNAL_LINK_ACCOUNT: 'External link account added successfully.',
  UPDATE_EXTERNAL_LINK_ACCOUNT: 'External link account updated successfully.',
  DELETE_WIDGET: 'Widget deleted successfully.',
  DELETE_LINK_ACCOUNT: 'Link account deleted successfully.',
  ACTIVATE_WIDGET: 'Widget activated successfully.',
  DEACTIVATE_WIDGET: 'Widget deactivated successfully.',
  ACTIVATE_USER: 'User(s) activated successfully.',
  DEACTIVATE_USER: 'User(s) deactivated successfully.',
  LOGIN_ERROR: 'Invalid username or password.',
  USER_LIST_ERROR: 'Unable to fetch users details.',
  WIDGET_REORDER_UPDATE_SUCCESS: 'Widgets reordered successfully.',
  WIDGETS_LIST_ERROR: 'Unable to fetch widgets details.',
  LINK_ACCOUNTS_LIST_ERROR: 'Unable to fetch link accounts details.',
  ADD_CITY: 'City added successfully.',
  UPDATE_CITY: 'City settings updated successfully.',
  CITIES_LIST_ERROR: 'Unable to fetch cities details.',
  USER_DETAILS_ERROR: 'Unable to fetch user information.',
  SAVE_EMAIL_SETTIGNS: 'Email settings saved successfully.',
  ERROR_APPLICABLE_WIDGETS: 'Unable to fetch a widgets list.',
  UPDATE_APPLICABLE_WIDGETS: 'Selected widgets settings saved successfully.',
  MAX_WIDGET_TYPE_ERROR: 'You have used the max allowable limit for this widget type. Please <strong><a href="https://www.esolutionsgroup.ca/citizen-portal/contact/" target="_blank">contact us </a></strong> if you would like to purchase additional widgets.',
  RESET_PASSWORD_SUCCESS: 'Link to reset password has been sent to the selected user(s) email id.',
  RESET_PASSWORD_ERROR: 'Failed to sent reset password link to the selected user(s). please try again.',
  ERROR_GET_ADDRESS_SETUP: 'Unable to fetch address configurations.',
  UPDATE_ADDRESS_SETUP: 'Address configurations updated successfully.',
  ERROR_GET_API_COMMU_CONFIG: 'Unable to fetch security details.',
  UPDATE_API_COMMU_CONFIG: 'Security details updated successfully.',
  ADD_WIDGET_RESOLVE_ERRORS: 'Please resolve all errors to be able to save the widget.',
  BANG_THE_TABLE_UPDATE_SUCCESS: 'Bang the table configuration updated successfully.',
  PERFECT_MIND_UPDATE_SUCCESS: 'Perfect mind configuration updated successfully.',
  WIDGET_NOT_ENABLED: 'This widget type is not applicable for your city. Please contact us to enable this widget type.',
  WIDGET_NOT_BELONG_TO_TENANT: 'Selected widget does not belong to the selected tenant.',
  USER_NOT_BELONG_TO_TENANT: 'Selected user does not belong to the selected tenant.',
  ADDRESS_SETUP_NOT_DONE_ERROR: 'Please contact us to setup your city’s address data in the portal.',
  ADDRESS_SETUP_NOT_DONE_LINK_ACCOUNT_ERROR: 'Please contact us to setup your city’s address data in the portal to be able to add a property dependent link account.',
  ADDRESS_SETUP_NOT_DONE_WIDGET_ERROR: 'Please contact us to setup your city’s address data in the portal to be able to add a property dependent widget.',
  TEST_API_LINKED_USERLIST_ERROR: 'Account must be linked for at least 1 user',
  CALENDAR_WIDGET_CATEGORY_DEPENDENT_INFO: `If the notifications are Category dependent, then the users will have the option to subscribe to notifications for each Calendar category. Otherwise, they will have the option to subscribe to notifications for the entire widget as a whole.`,
  CALENDAR_FILTER_BY_LOCATION_INFO: `Enable the below option only if you have added "Location" as a field set in your selected Calendar(s). This will allow your citizens to view events specific to their preferred location by selecting the location(s) from the widget settings. Please note that if any of the events do not have any value in the Location field, they won’t be displayed in the widget.`
}

export const CONTACT_URL = "https://ghddigitalpss.com/citizen-portal/contact"
