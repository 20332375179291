import store from '@/store'
export const tenantSpecificURL = (url, isQuery = false) => {
  const userRole = store.state.common.userRole
  const selectedTenant = store.state.common.selectedTenant
  if (userRole === 'SysAdmin') {
    if (isQuery) {
      let query = '?'
      selectedTenant.forEach((tenant, index) => {
        query += `tenant_Id=${tenant.tenantID}`
        if ((index + 1) !== selectedTenant.length) {
          query += '&'
        }
      })
      return `${url}${query}`
    }
    return `${url}/${selectedTenant[0].tenantID}`
  }
  return url
}
