<template>
  <div>
    <section class="py-3 px-5 page-banner">
      <router-link
        to="/settings"
        v-if="activeTabName != 'General'"
        class="go-back-link"
        ><BIconArrowLeftShort />Back to settings</router-link
      >
      <h3 class="page-header py-3">{{ activeTabName }}</h3>
      <b-row class="justify-content-between">
        <b-col cols="12" class="col-sm-6">
          <b-nav role="navigation">
            <b-nav-item
              to="/settings/settings-categories/general"
              exact
              exact-active-class="active"
              >General</b-nav-item
            >
            <b-nav-item
              to="/settings/settings-categories/email"
              exact
              exact-active-class="active"
              >Email</b-nav-item
            >
            <b-nav-item
              to="/settings/settings-categories/link-accounts"
              exact
              exact-active-class="active"
              >Link accounts</b-nav-item
            >
          </b-nav>
        </b-col>
      </b-row>
    </section>
    <section class="py-4 px-5">
      <router-view></router-view>
    </section>
  </div>
</template>
<script>
import { BIconArrowLeftShort } from 'bootstrap-vue'
export default {
  name: 'SettingsCategories',
  data () {
    return {
      activeTabName: ''
    }
  },
  components: {
    BIconArrowLeftShort
  },
  created () {
    this.setActivatedTab()
  },
  methods: {
    setActivatedTab () {
      if (this.$route.name === 'General-settings') {
        this.activeTabName = 'General'
      } else if (this.$route.name === 'Email-settings') {
        this.activeTabName = 'Email'
      } else if (this.$route.name === 'Settings-link-accounts') {
        this.activeTabName = 'Link accounts'
      } else if (this.$route.name === 'Settings-add-link-account') {
        this.activeTabName = 'Add link account'
      } else if (this.$route.name === 'Settings-edit-link-account') {
        this.activeTabName = 'Edit link account'
      } else if (this.$route.name === 'Settings-add-external-link-account') {
        this.activeTabName = 'Add external link account'
      } else if (this.$route.name === 'Settings-edit-external-link-account') {
        this.activeTabName = 'Edit external link account'
      } else {
        this.activeTabName = ''
      }
    }
  },
  watch: {
    $route () {
      this.setActivatedTab()
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
