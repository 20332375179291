export const b2cPolicies = {
  names: {
    signUpSignIn: process.env.VUE_APP_CUSTOMPOLICY
  },
  authorities: {
    signUpSignIn: {
      authority: process.env.VUE_APP_AUTHORITY
    }
  },
  authorityDomain: process.env.VUE_APP_AUTHORITYDOMAIN
}
