import { HTTP } from '../../utilities/http-common'
import { ServiceUrls } from '../../utilities/serviceUrls'
import { tenantSpecificURL } from '@/utilities/utilities'
const getDefaultState = () => {
  return {
    users: []
  }
}
// initial state
const state = getDefaultState()

// getters
const getters = {

}
// actions
const actions = {
  getUsers ({ commit }) {
    return HTTP.get(tenantSpecificURL(ServiceUrls.getUsers, true))
      .then((res) => {
        commit('setUsers', res.data)
        return res
      })
      .catch((error) => {
        commit('setUsers', [])
        return Promise.reject(error)
      })
  },
  getUserDetails ({ commit }, userID) {
    return HTTP.get(`${ServiceUrls.getUserDetails}/${userID}`)
      .then((res) => {
        return res
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  },
  activateOrDeactivateUsers ({ commit }, postObj) {
    return HTTP.post(`${ServiceUrls.activateOrDeactivateUser}`, postObj)
      .then((res) => {
        return res
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  },
  resetPassword ({ commit }, postObj) {
    return HTTP.post(`${ServiceUrls.resetPassword}`, postObj)
      .then((res) => {
        return res
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }
}
// mutations
const mutations = {
  resetState (state) {
    Object.assign(state, getDefaultState())
  },
  setUsers (state, payload) {
    state.users = payload
  }
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
