<template>
  <div id="app">
    <router-view />
    <CustomToaster />
  </div>
</template>
<script>
import { mapState } from 'vuex'
import CustomToaster from './components/common/CustomToaster.vue'
import { HTTP } from './utilities/http-common'
export default {
  name: 'App',
  components: {
    CustomToaster
  },
  computed: {
    ...mapState({
      idToken: (state) => state.common.idToken
    })
  },
  created () {
    this.assignToken()
  },
  methods: {
    async assignToken () {
      HTTP.interceptors.request.use(
        async (request) => {
          await this.$store.dispatch('common/getToken')
          if (this.idToken) {
            request.headers.Authorization = `Bearer ${this.idToken}`
          }
          return request
        },
        (error) => {
          return Promise.reject(error)
        }
      )
      HTTP.interceptors.response.use(
        (response) => {
          return response
        },
        async (error) => {
          if (error.response.status === 401) {
            if (
              error.response.request.responseURL.includes(
                process.env.VUE_APP_BASEURL
              )
            ) {
              error.response.statusText = '401-Unauthorized'
              error.response.data = '401-Unauthorized'
              await this.$store.dispatch('common/signOut')
            }
          }
          return Promise.reject(error)
        }
      )
    }
  }
}
</script>
<style lang="scss">
#app {
  //font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
